﻿//NB: copied from ComponentStructures.ts
class uiBlockData {
	loaded = false
}

class uiBlock {
	id = ""; div = null; bgd = null;

	constructor(id) {
		this.id = id;
	}

	show() {
		if (!this.div) {
			this.div = document.getElementById(this.id);
			this.bgd = document.getElementById(this.id + 'Background');
		}
		this.div.classList.add("show");
		this.bgd.style.display = "block";
	}

	hide() {
		this.div.classList.remove("show");
		this.bgd.style.display = "none";
	}
}

export function showToastMessage(message, timeout = 3000) {
	var _toast = '';
	var panelList = document.querySelectorAll(".sk-panel .show");
	if (panelList && panelList.length > 0) {
		var panelId = panelList[0].id;
		_toast = document.getElementById(panelId + 'Toast');
	}
	else _toast = document.getElementById('showToast');

	if(_toast!=null){

	if (message === '' || message === undefined) {
		_toast.innerHTML = '';
		_toast.classList.remove('show');
		_toast.classList.add('hide');
	}
	else {
		_toast.innerHTML = message;
		_toast.classList.remove('hide');
		_toast.classList.add('show');
	}
	setTimeout(() => {
		_toast.classList.remove('show');
		_toast.classList.add('hide');
	}, timeout);
}
}

export function toggleAjaxLoader (show) {
	var ajaxLoader = document.getElementById("ajaxloader");
	if (show) {
		ajaxLoader.classList.add("show");
		ajaxLoader.classList.remove("hide");
		document.body.classList.add('no-scroll');
	} else {
		ajaxLoader.classList.add("hide");
		ajaxLoader.classList.remove("show");
		document.body.classList.remove('no-scroll')
	}
}