﻿const { PaymentPlans, CapshineSample, SulekhaProduct, SulekhaAppSource, MobileApps, MobilePages, AppEnvironment, CSMainAPIs, SlkContainer, SulekhaAPI, APIHelper, SulekhaCoreDI, AppPerPanel, InitPanel } = require("./capshine");
const { showToastMessage, toggleAjaxLoader } = require("../js/capshine-infrastructure");


//TODO: Remove
window.showToastMessage = showToastMessage;
window.toggleAjaxLoader = toggleAjaxLoader;

export default class CapshineApp {
	Product = SulekhaProduct.LocalServices;
	AppSource = SulekhaAppSource.Web;

	Register = function (config) {
		this.Product = config.product;
		this.AppSource = config.appSource;

		this.SetCoreDependencies();
		this.SetCapshineDependencies();

		this.SetAppImplementations();
		this.InitAnalyticsTracking();
	};

	SetCoreDependencies = function () {
		SulekhaCoreDI.LogClick = function (ev, action) { console.log('TODO: Log this to GA:', action); } //SulGA.gtag({ name: 'Link Click', action: action }); };

		SulekhaCoreDI.LogApiCall = function (apiName, url, method) {
			console.log('TODO: Log this API Call:', { name: 'API Call', apiName, url, method }); } //TODO: SulGA.gtag(); };

		SulekhaCoreDI.showToast = function (dialog) { showToastMessage(dialog.message); };

		APIHelper.setApiInfo({ runningCount: 0, loadMessage: '', hadError: false, errorMessage: '' }); //TODO: never show?
	}

	SetCapshineDependencies = function () {
		var environment = AppEnvironment.development;
		var env = "";

		if (document.getElementById("hdnEnvironment"))
			env = document.getElementById("hdnEnvironment").value;

		//env="production";
		switch (env.toLowerCase()) {
			case "qa":
				environment = AppEnvironment.qa;
				break;
			case "production":
				environment = AppEnvironment.production;
				break;
			default:
				environment = AppEnvironment.development;
				break;
		}

		CSMainAPIs.SetEnvironmentUrls(environment);
		//MainAPIs.SilkWeb.Server.baseUrl = 'https://sl2021.sulekha.com/'; //NOTE: For devs, uncomment this. Also make it dev.sulekha.com
	}

	SetAppImplementations = function () {
		SulekhaAPI.setAppHeadersImpl = function (headers) {
			var authInfo = JSON.stringify({
					UserPid: 50944657, // dinesh pid
					AppSource: '0',
					ClientType: '0',
				}),
				deviceInfo = '';
			headers.append('Sulekha-API-Authorize', authInfo);
			headers.append('Sulekha-API-Device', deviceInfo);
			headers.append('ClientId', 'capshine');
		};
	}

	InitAnalyticsTracking = function () {
		document.querySelectorAll('.has-action').forEach((el) => {
			if (el.hasAttribute('track-action')) {
				el.addEventListener('click', function (ev) {
					SulekhaCoreDI.LogClick(ev, el.getAttribute('action-name'));
				});
			}
		});
	}

	InitPanel = function (name, props) {
		var id = name + '_Panel';
		var panel = document.getElementById(id);
		if (!panel) {
			panel = document.createElement('div');
			panel.id = id;
			var interop = document.getElementById('vueInteropDiv');
			interop.appendChild(panel);
		}

		var appAndVM = AppPerPanel[name];

		if (!appAndVM) {
			InitPanel(name, props);
			appAndVM = AppPerPanel[name];
		}

		return appAndVM;
	}
}
